import React, { useState } from "react";
import "./Navbar.scss";
import logo from "../../assets/2.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLeft, setIsOpenLeft] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsOpenLeft(isOpenLeft);
  };

  const toggleMenuLeft = () => {
    setIsOpenLeft(!isOpenLeft);
    setIsOpen(isOpen);
  };

  return (
    <div className="navbar-container-main">
      <nav className="navbar-container">
        {/* Left Menu */}
        <div
          className={`hamburger-menu-left ${isOpenLeft ? "openLeft" : ""}`}
          onClick={toggleMenuLeft}
        >
          <div className="line-left"></div>
          <div className="line-left"></div>
          <div className="line-left"></div>
        </div>
        <ul className={`navbar-links-left ${isOpenLeft ? "openLeft" : ""}`}>
          <li>
            <a href="#section-what">What</a>
          </li>
          <li>
            <a href="#section-why">Why</a>
          </li>
          <li>
            <a href="#section-how">How</a>
          </li>
        </ul>

        {/* Logo */}
        <div
          className={`logo-container ${
            isOpenLeft ? "openLeft" : isOpen ? "open" : ""
          }`}
        >
          <a href="#section-what">
            <img src={logo} alt="Logo" />
          </a>
        </div>

        {/* Right Menu */}
        <div
          className={`hamburger-menu ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
          <li>
            <a href="#section-who">Who</a>
          </li>
          <li>
            <a href="#section-when">When</a>
          </li>
          <li>
            <a href="#section-where">Where</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
